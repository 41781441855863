body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #141436;
}

.hero-bg {
    background-image: url(./ui/assets/effext.png);
    background-position: center;
    background-repeat: repeat;
    background-size: cover;
    height: max-content;
}

.feature-bg {
    background-image: url(./ui/assets/dark-denim-3\ 1.png);
    background-position: center;
    background-repeat: repeat;
    background-size: contain;
    height: max-content;
}
.full{
    background-image: url(./ui/assets/full.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: max-content;
}
.full2{
    background-image: url(./ui/assets/pngwing2.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}